import firebase from 'firebase/app';
import cryptojs from 'crypto-js';

export interface IFirestoreTransformable {
  loadFromFirestoreData(
    id: string,
    data: firebase.firestore.DocumentData
  ): this;
  toFirestore(): firebase.firestore.DocumentData;
  getProcessedDataToWrite(): firebase.firestore.DocumentData;
}

abstract class FirestoreObject<TData> implements IFirestoreTransformable {
  protected data: TData;
  protected dataToWrite: TData;
  protected dirtyFields: Array<keyof TData> = [];

  constructor() {
    this.data = this.getDefaultData();
    this.dataToWrite = this.getDefaultData();
  }

  loadFromFirestoreData(
    id: string,
    data: firebase.firestore.DocumentData
  ): this {
    const key = this.getEncryptionKey();
    const fieldsToEncrypt = this.getFieldsToEncrypt();
    if (!!data.__ev && !!key && fieldsToEncrypt.length > 0) {
      fieldsToEncrypt.forEach((field) => {
        data[field] = cryptojs.AES.decrypt(data[field], key).toString(
          cryptojs.enc.Utf8
        );
      });
    }

    this.data = this.fromFirestore(id, data);
    return this;
  }

  abstract getDefaultData(): TData;

  abstract getID(): string;

  get<Key extends keyof TData>(key: Key) {
    return this.data[key];
  }

  protected getNewOrExisting<Key extends keyof TData>(key: Key) {
    return this.dirtyFields.includes(key)
      ? this.dataToWrite[key]
      : this.data[key];
  }

  protected markChangesAsCommitted() {
    this.dataToWrite = this.getDefaultData();
    this.dirtyFields = [];
  }

  set<Key extends keyof TData, Value extends TData[Key]>(
    key: Key,
    value: Value
  ): this {
    this.dataToWrite[key] = value;
    this.dirtyFields.push(key);
    return this;
  }

  hasValidData(): boolean {
    return true;
  }

  isDirty(): boolean {
    return this.dirtyFields.length > 0;
  }

  abstract fromFirestore(
    id: string,
    data: firebase.firestore.DocumentData
  ): TData;

  abstract toFirestore(): firebase.firestore.DocumentData;

  getEncryptionKey(): string | null {
    return null;
  }

  getFieldsToEncrypt(): Array<string> {
    return [];
  }

  /**
   * Gets the encrypted (if enabled) payload to write to Firestore. This method should only be used when writing to Firestore.
   */
  getProcessedDataToWrite(): firebase.firestore.DocumentData {
    const data = this.toFirestore();
    const key = this.getEncryptionKey();
    const fieldsToEncrypt = this.getFieldsToEncrypt();

    Object.keys(data).forEach((k) => {
      if (data[k] === undefined) {
        delete data[k];
      }
    });

    if (key === null || fieldsToEncrypt.length === 0) {
      return data;
    }

    fieldsToEncrypt.forEach((field) => {
      data[field] = cryptojs.AES.encrypt(data[field], key).toString();
    });
    data.__encf = fieldsToEncrypt;
    data.__ev = 1;

    return data;
  }
}

export default FirestoreObject;
