// Set remote config default values here
export const DEFAULT_VALUES = {
  can_create_todo: true,
  exampleString: '',
};

// Set how long to fetch config values
export const MIN_FETCH_INTERVAL_MS =
  window.location.hostname === 'localhost'
    ? 60000 // 1 minute
    : 1800000; // 30 mins
