import * as React from 'react';

import styles from './Card.module.css';

function Card(props: {
  children: React.ReactNode;
  title?: string;
}): JSX.Element {
  return (
    <div className={styles.card}>
      {props.title && <h2 className={styles.title}>{props.title}</h2>}
      {props.children}
    </div>
  );
}

export default Card;
