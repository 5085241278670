import * as React from 'react';
import classnames from 'classnames';

import FormField from './FormField';

import styles from './TextInput.module.css';

const { useEffect } = React;

type Props = {
  autoComplete?: string;
  disabled?: boolean;
  focusOnLoad?: boolean;
  helpText?: string;
  label?: string;
  onChange: (value: string) => void;
  onHitEnter?: () => void;
  onFocus?: () => void;
  placeholder?: string;
  prefix?: React.ReactNode;
  type?: 'text' | 'password' | 'number';
  value: string;
};

function TextInput(props: Props): JSX.Element {
  let textInput: HTMLInputElement | null = null;

  useEffect(() => {
    if (!!props.focusOnLoad && !!textInput && !props.value) {
      textInput.focus();
    }
  }, [textInput, props.value, props.focusOnLoad]);

  return (
    <FormField label={props.label} helpText={props.helpText}>
      <div className={styles.container}>
        {props.prefix && <div className={styles.prefix}>{props.prefix}</div>}
        <input
          autoComplete={props.autoComplete}
          className={classnames({
            [styles.text_input]: true,
            [styles.disabled]: props.disabled,
          })}
          disabled={props.disabled}
          type={props.type}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            props.onChange(e.currentTarget.value);
          }}
          onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
            if (!props.onHitEnter) {
              return;
            }

            if (e.keyCode === 13) {
              props.onHitEnter();
            }
          }}
          onFocus={props.onFocus}
          placeholder={props.placeholder}
          ref={(input) => {
            textInput = input;
          }}
          value={props.value}
        />
      </div>
    </FormField>
  );
}

TextInput.defaultProps = {
  disabled: false,
  placeholder: '',
  type: 'text',
};

export default TextInput;
