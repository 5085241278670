// --GENERATED CODE--
// Used by React hooks:
import {
  useFirestoreCollection,
  useFirestoreCollectionOnce,
  useFirestoreDocument,
  useFirestoreDocumentOnce,
  useFirestoreMutation,
  useFirestoreDeletion,
  QueryWhereClause,
} from 'prima/firestore/hooks';
// --END OF GENERATED CODE--
import firebase from 'firebase/app';
import { FirestoreObject } from 'prima/firestore/types';

export interface IUserProfile {
  id: string;
  userID: string;
  displayName: string;
  profilePhotoURL: string;
}

class UserProfile extends FirestoreObject<IUserProfile> {
  getID() {
    return this.data.id;
  }

  getDefaultData() {
    return {
      id: '',
      userID: '',
      displayName: '',
      profilePhotoURL: '',
    };
  }

  fromFirestore(
    id: string,
    data: firebase.firestore.DocumentData
  ): IUserProfile {
    return {
      id,
      userID: data.user_id,
      displayName: data.display_name,
      profilePhotoURL: data.profile_photo,
    };
  }

  toFirestore(): firebase.firestore.DocumentData {
    return {
      user_id: this.getNewOrExisting('userID'),
      display_name: this.getNewOrExisting('displayName'),
      profile_photo: this.getNewOrExisting('profilePhotoURL'),
    };
  }
}

export default UserProfile;
// --GENERATED CODE--
// The following lines contain generated code. Do not make changes directly to this code.
// Any changes will be overwritten. To regenerate, run 'npm run gen-data-hooks UserProfile <COLLECTION_NAME>'.

export function useUserProfileCollection(filters?: Array<QueryWhereClause>) {
  return useFirestoreCollection<UserProfile>({
    collection: 'user_profiles',
    create: () => new UserProfile(),
    filters,
  });
}

export function useUserProfileCollectionOnce(filters?: Array<QueryWhereClause>) {
  return useFirestoreCollectionOnce<UserProfile>({
    collection: 'user_profiles',
    create: () => new UserProfile(),
    filters,
  });
}

export function useUserProfileDocument(documentID: string) {
  return useFirestoreDocument({
    collection: 'user_profiles',
    documentID,
    create: () => new UserProfile(),
  });
}

export function useUserProfileDocumentOnce(documentID: string) {
  return useFirestoreDocumentOnce({
    collection: 'user_profiles',
    documentID,
    create: () => new UserProfile(),
  });
}

export function useUserProfileMutation(documentID?: string) {
  return useFirestoreMutation<UserProfile>({
    collection: 'user_profiles',
    documentID,
  });
}

export function useUserProfileDeletion(documentID: string) {
  return useFirestoreDeletion({
    collection: 'user_profiles',
    documentID,
  });
}

// --END OF GENERATED CODE--
