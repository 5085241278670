import firebase from 'firebase/app';

function getFirestoreCollectionQuery({
  collection,
  documentID,
}: {
  collection: string;
  documentID: string;
}): firebase.firestore.DocumentReference<firebase.firestore.DocumentData> {
  return firebase.firestore().collection(collection).doc(documentID);
}

export default getFirestoreCollectionQuery;
