import firebase from 'firebase/app';
import { useCallback, useMemo, useState } from 'react';
import { IFirestoreTransformable } from '../types/FirestoreObject';
import OperationState from '../helpers/OperationState';

function useFirestoreMutation<TObjectType extends IFirestoreTransformable>({
  collection,
  documentID,
}: {
  collection: string;
  documentID?: string;
}) {
  const db = useMemo(() => firebase.firestore(), []);
  const [state, setState] = useState<OperationState>(OperationState.PENDING);

  const save = useCallback(
    (data: TObjectType) => {
      return new Promise<firebase.firestore.DocumentReference<firebase.firestore.DocumentData> | null>(
        (resolve) => {
          setState(OperationState.IN_PROGRESS);
          if (documentID != null) {
            db.collection(collection)
              .doc(documentID)
              .set(data.getProcessedDataToWrite())
              .then((doc) => {
                setState(OperationState.SUCCESS);
                resolve(null);
              })
              .catch((reason) => {
                console.warn(`Firestore write failed: ${reason}`);
                setState(OperationState.FAILED);
              });
          } else {
            db.collection(collection)
              .add(data.getProcessedDataToWrite())
              .then((doc) => {
                resolve(doc);
                setState(OperationState.SUCCESS);
              })
              .catch((reason) => {
                console.warn(`Firestore write failed: ${reason}`);
                setState(OperationState.FAILED);
              });
          }
        }
      );
    },
    [setState, collection, documentID, db]
  );

  return {
    save,
    state,
  };
}

export default useFirestoreMutation;
