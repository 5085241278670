import * as React from 'react';

import styles from './ButtonContainer.module.css';

type Props = {
  children: React.ReactNode;
};

function ButtonContainer(props: Props): JSX.Element {
  return <div className={styles.button_container}>{props.children}</div>;
}

export default ButtonContainer;
