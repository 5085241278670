import { remoteConfig } from 'services/firebase';
import { MIN_FETCH_INTERVAL_MS, DEFAULT_VALUES } from 'helpers/RemoteConfig';

const RemoteConfig = {
  _remoteConfig: remoteConfig,
  isInitialised: false,

  initialise() {
    this._remoteConfig.settings.minimumFetchIntervalMillis = MIN_FETCH_INTERVAL_MS;
    this._remoteConfig.defaultConfig = DEFAULT_VALUES;
    this._remoteConfig.fetchAndActivate().then(() => {
      window.location.hostname === 'localhost' &&
        console.log('Remote config initialised.');
      this.isInitialised = true;
    });
  },

  getValue(key: string) {
    return this._remoteConfig.getValue(key);
  },
};

export default RemoteConfig;
