import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import prettyBytes from 'pretty-bytes';
import FormField from './FormField';
import { Text } from '../text';

import styles from './FileInput.module.css';

type Props = {
  label?: string;
  helpText?: string;
  accept?: string;
  prompt: string;
  onFileSelected: (file: File) => void;
};

function FileInput(props: Props): JSX.Element {
  const { onFileSelected } = props;
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInput = useRef<HTMLInputElement>(null);
  const onBeginFileSelect = useCallback(() => {
    fileInput.current?.click();
  }, [fileInput]);
  const onFileChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const fileList = e.currentTarget.files;
      if (!fileList) {
        return;
      }

      if (fileList.length < 1) {
        return;
      }

      const selectedFile = fileList[0];
      setSelectedFile(selectedFile);
      onFileSelected(selectedFile);
    },
    [setSelectedFile, onFileSelected]
  );

  return (
    <FormField {...props}>
      <div className={styles.container} onClick={onBeginFileSelect}>
        <input
          className={styles.file_input}
          accept={props.accept}
          ref={fileInput}
          type="file"
          onChange={onFileChange}
        />
        <div>
          <Text
            weight="semibold"
            color={selectedFile ? 'primary' : 'secondary'}
          >
            {selectedFile ? (
              <>
                {selectedFile.name} ({selectedFile.type},{' '}
                {prettyBytes(selectedFile.size)})
              </>
            ) : (
              props.prompt
            )}
          </Text>
        </div>
      </div>
    </FormField>
  );
}

FileInput.defaultProps = {
  accept: '',
  prompt: 'Click to choose a file',
};

export default FileInput;
