import * as React from 'react';
import { useCallback, useState } from 'react';
import Button from '../../ui/forms/Button';
import { ConfirmationDialog } from '../../ui/modals';
import firebase from 'firebase/app';

type Props = {
  label: React.ReactNode;
  promptToConfirm: boolean;
  confirmationDialogTitle: string;
  confirmationDialogMessage: string;
  tooltip?: string;
  tooltipDirection?:
    | 'up'
    | 'down'
    | 'left'
    | 'right'
    | 'up-left'
    | 'up-right'
    | 'down-left'
    | 'down-right';
  subtle?: boolean;
};

function LogOutButton(props: Props): JSX.Element {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const onLogOut = useCallback(() => {
    firebase.auth().signOut();
  }, []);
  const onShowPrompt = useCallback(() => {
    setShowConfirmation(true);
  }, [setShowConfirmation]);

  return (
    <>
      {showConfirmation && (
        <ConfirmationDialog
          title={props.confirmationDialogTitle}
          message={props.confirmationDialogMessage}
          onCancel={() => {
            setShowConfirmation(false);
          }}
          onConfirm={onLogOut}
        />
      )}
      <Button
        label={props.label}
        onClick={props.promptToConfirm ? onShowPrompt : onLogOut}
        tooltip={props.tooltip}
        tooltipDirection={props.tooltipDirection}
        subtle={props.subtle}
      />
    </>
  );
}

LogOutButton.defaultProps = {
  label: 'Log out',
  promptToConfirm: true,
  confirmationDialogTitle: 'Log out?',
  confirmationDialogMessage: 'Are you sure you want to log out?',
};

export default LogOutButton;
