import * as React from 'react';
import classnames from 'classnames';

import styles from './Heading.module.css';

type Props = {
  children: React.ReactNode;
  type: 'page-title' | 'section-title' | 'sub-heading';
  subtitle?: string;
};

function Heading({ children, type, subtitle }: Props): JSX.Element {
  switch (type) {
    case 'page-title':
      return (
        <h1 className={classnames([styles.heading, styles.page_title])}>
          {children}{' '}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </h1>
      );
    case 'section-title':
      return (
        <h2 className={styles.heading}>
          {children}{' '}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </h2>
      );
    case 'sub-heading':
      return (
        <h3 className={styles.heading}>
          {children}{' '}
          {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
        </h3>
      );
  }
}

export default Heading;
