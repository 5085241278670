import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import styles from './Modal.module.css';

type Props = {
  children: React.ReactNode;
  onClose?: () => void;
  title?: string;
  headerContent?: React.ReactNode;
};

function Modal(props: Props): JSX.Element {
  return (
    <div className={styles.container}>
      <div className={styles.modal}>
        <div className={styles.header}>
          {props.headerContent}
          {props.title && <h2 className={styles.title}>{props.title}</h2>}
          {props.onClose && (
            <button
              className={styles.close_button}
              onClick={() => {
                props.onClose && props.onClose();
              }}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )}
        </div>
        <div className={styles.content}>{props.children}</div>
      </div>
    </div>
  );
}

export default Modal;
