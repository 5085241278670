import * as React from 'react';
import classnames from 'classnames';

import styles from './Center.module.css';

type Props = {
  children: React.ReactNode;
  horizontal: boolean;
  vertical: boolean;
};

function Center({ children, horizontal, vertical }: Props): JSX.Element {
  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.h_center]: horizontal,
        [styles.v_center]: vertical,
      })}
    >
      {children}
    </div>
  );
}

Center.defaultProps = {
  horizontal: false,
  vertical: false,
};

export default Center;
