import * as React from 'react';
import classnames from 'classnames';
import { useFirebaseUser } from 'prima/auth/hooks';
import { useUserProfileCollectionOnce } from 'data/UserProfile';

import styles from './UserProfile.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';

type Props = {
  userID?: string;
  showDisplayName: boolean;
  size: 'small' | 'medium' | 'large';
};

function UserProfile({ userID, showDisplayName, size }: Props): JSX.Element {
  const currentUser = useFirebaseUser();
  const [results] = useUserProfileCollectionOnce([
    { field: 'user_id', op: '==', value: userID ?? currentUser?.uid },
  ]);

  let photoURL: string | null = null,
    displayName: string | null = null;
  if (results.length > 0) {
    photoURL = results[0].get('profilePhotoURL');
    displayName = results[0].get('displayName');
  }

  return (
    <div
      className={classnames({
        [styles.container]: true,
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
      })}
    >
      {photoURL ? (
        <div
          className={styles.photo}
          style={{
            backgroundImage: `url(${photoURL})`,
          }}
        />
      ) : (
        <div className={styles.photo}>
          {displayName ? (
            <>{displayName[0].toUpperCase()}</>
          ) : (
            <FontAwesomeIcon icon={faUserAlt} />
          )}
        </div>
      )}
      {showDisplayName && (
        <div className={styles.display_name}>
          {displayName ?? 'An unknown user'}
        </div>
      )}
    </div>
  );
}

UserProfile.defaultProps = {
  showDisplayName: true,
  size: 'medium',
};

export default UserProfile;
