import * as React from 'react';
import { useMemo } from 'react';
import { format } from 'date-fns';

type Props = {
  date: Date | number;
  format: string;
};

function DateLabel({ date, format: dateFormat }: Props): JSX.Element {
  const dateValue = useMemo(
    () => (typeof date === 'object' ? date : new Date(Math.ceil(date * 1000))),
    [date]
  );
  return <>{format(dateValue, dateFormat)}</>;
}

DateLabel.defaultProps = {
  format: 'E d LLL Y',
};

export default DateLabel;
