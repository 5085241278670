import * as React from 'react';
import classnames from 'classnames';
import { Loading } from '../spinners';

import styles from './Button.module.css';

export type ButtonColour = 'normal' | 'danger' | 'highlight';

type Props = {
  disabled?: boolean;
  label: React.ReactNode;
  onClick: () => void;
  primary?: boolean;
  showSpinner?: boolean;
  size: 'normal' | 'small';
  subtle: boolean;
  tooltip?: string;
  tooltipDirection?:
    | 'up'
    | 'down'
    | 'left'
    | 'right'
    | 'up-left'
    | 'up-right'
    | 'down-left'
    | 'down-right';
  colour: ButtonColour;
};

function Button(props: Props): JSX.Element {
  return (
    <button
      className={classnames({
        [styles.button]: true,
        [styles.primary]: props.primary,
        [styles.disabled]: props.disabled || props.showSpinner,
        [styles.subtle]: props.subtle,
        [styles.danger]: props.colour === 'danger',
        [styles.highlight]: props.colour === 'highlight',
        [styles.small]: props.size === 'small',
      })}
      disabled={props.disabled}
      onClick={props.onClick}
      aria-label={props.tooltip}
      data-balloon-pos={props.tooltipDirection}
    >
      {props.label}
      {props.showSpinner && <Loading padding="0" margin="0 0 0 0.5rem" />}
    </button>
  );
}

Button.defaultProps = {
  disabled: false,
  primary: false,
  showSpinner: false,
  size: 'normal',
  subtle: false,
  tooltipDirection: 'up',
  colour: 'normal',
};

export default Button;
