import * as React from 'react';

type TextSize =
  | 'tiny'
  | 'xsmall'
  | 'small'
  | 'medium'
  | 'large'
  | 'xlarge'
  | 'xxlarge'
  | 'huge';

type TextColour =
  | 'primary'
  | 'secondary'
  | 'subtle'
  | 'positive'
  | 'negative'
  | 'heading';

type TextWeight = 'light' | 'normal' | 'semibold' | 'bold';

export type Props = {
  color: TextColour;
  children: React.ReactNode;
  size: TextSize;
  weight: TextWeight;
};

const SIZE_MAPPING: { [key in TextSize]: string } = {
  tiny: '0.5rem',
  xsmall: '0.65rem',
  small: '0.8rem',
  medium: '1rem',
  large: '1.25rem',
  xlarge: '1.5rem',
  xxlarge: '2rem',
  huge: '2.5rem',
};

const COLOUR_MAPPING: { [key in TextColour]: string } = {
  primary: 'var(--color-gray-dark-n75)',
  secondary: 'var(--color-gray-light-n50)',
  subtle: 'var(--color-gray-light-n25)',
  positive: 'var(--color-highlight-green)',
  negative: 'var(--color-highlight-red)',
  heading: 'var(--color-gray-dark-p12)',
};

const WEIGHT_MAPPING: { [key in TextWeight]: number } = {
  light: 200,
  normal: 400,
  semibold: 600,
  bold: 800,
};

function Text({ children, size, color, weight }: Props): JSX.Element {
  return (
    <span
      style={{
        color: COLOUR_MAPPING[color],
        fontSize: SIZE_MAPPING[size],
        fontWeight: WEIGHT_MAPPING[weight],
      }}
    >
      {children}
    </span>
  );
}

Text.defaultProps = {
  color: 'primary',
  size: 'medium',
  weight: 'normal',
};

export default Text;
