import * as React from 'react';
import { useCallback, useState } from 'react';
import { TextInput, ButtonContainer, Button } from '../../ui/forms';
import { NoticeCard } from '../../ui/containers';
import { useFirebaseUser } from 'prima/auth/hooks';
import firebase from 'firebase/app';

type Props = {};

function ChangePasswordForm(props: Props): JSX.Element {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setConfirmNewPassword] = useState('');
  const [isSaving, setIsSaving] = useState(false);
  const [success, setSuccess] = useState<boolean | null>(null);
  const [error, setError] = useState('');

  const currentUser = useFirebaseUser();

  const onSave = useCallback(async () => {
    if (!currentUser) {
      setError(
        "Your password couldn't be changed at the moment. Try logging out and logging in again, then change your password."
      );
      setSuccess(false);
      return;
    }

    setSuccess(null);
    setIsSaving(true);
    setError('');
    try {
      const credential = firebase.auth.EmailAuthProvider.credential(
        currentUser.email ?? '',
        currentPassword
      );
      await currentUser.reauthenticateWithCredential(credential);

      await currentUser.updatePassword(newPassword);

      setSuccess(true);
      setCurrentPassword('');
      setNewPassword('');
      setConfirmNewPassword('');
    } catch (e) {
      setError(
        "Your password couldn't be changed. Check that your current password was typed correctly."
      );
      setSuccess(false);
    } finally {
      setIsSaving(false);
    }
  }, [
    currentPassword,
    newPassword,
    currentUser,
    setIsSaving,
    setSuccess,
    setError,
    setCurrentPassword,
    setNewPassword,
    setConfirmNewPassword,
  ]);

  return (
    <>
      <TextInput
        type="password"
        value={currentPassword}
        onChange={setCurrentPassword}
        label="Current password"
        disabled={isSaving}
      />
      <TextInput
        type="password"
        value={newPassword}
        onChange={setNewPassword}
        label="New password"
        disabled={isSaving}
      />
      <TextInput
        type="password"
        value={confirmNewPassword}
        onChange={setConfirmNewPassword}
        label="Confirm new password"
        disabled={isSaving}
      />
      <ButtonContainer>
        <Button
          label="Change password"
          onClick={onSave}
          disabled={
            !newPassword ||
            !currentPassword ||
            newPassword !== confirmNewPassword
          }
          showSpinner={isSaving}
        />
      </ButtonContainer>
      {success && (
        <NoticeCard type="success" title="Password changed!">
          Your password was changed successfully. Please use your new password
          when logging in again.
        </NoticeCard>
      )}
      {success === false && (
        <NoticeCard type="error" title="Unable to change password">
          {error}
        </NoticeCard>
      )}
    </>
  );
}

export default ChangePasswordForm;
