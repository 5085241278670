import getFirestoreCollectionQuery, {
  QueryWhereClause,
} from '../helpers/getFirestoreCollectionQuery';
import { useEffect, useState } from 'react';
import useFirebaseUser from '../../auth/hooks/useFirebaseUser';
import { IFirestoreTransformable } from '../types/FirestoreObject';

function useFirestoreCollectionOnce<
  TObjectType extends IFirestoreTransformable
>(
  {
    collection,
    filters = [],
    create,
  }: {
    collection: string;
    filters?: Array<QueryWhereClause>;
    create: () => TObjectType;
  },
  waitForAuth: boolean = true
): [results: Array<TObjectType>, isLoading: boolean] {
  const user = useFirebaseUser();
  const [results, setResults] = useState<Array<TObjectType>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const createFunc = create.toString();

  useEffect(
    () => {
      if (waitForAuth && user === null) {
        return;
      }

      getFirestoreCollectionQuery({
        collection,
        filters,
      })
        .get()
        .then((snapshot) => {
          const snapshotResults: Array<TObjectType> = [];
          snapshot.forEach((result) => {
            try {
              snapshotResults.push(
                create().loadFromFirestoreData(result.id, result.data())
              );
            } catch (e) {
              console.warn(`Error when parsing document: ${e.message}`);
            }
          });
          setResults(snapshotResults);
          setIsLoading(false);
        });

      return () => {};
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [waitForAuth, setResults, setIsLoading, collection, user, createFunc]
  );

  return [results, isLoading];
}

export default useFirestoreCollectionOnce;
