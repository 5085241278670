import * as React from 'react';
import { LoginForm } from 'prima/auth/components';

import styles from './LoginPage.module.css';

import logo from 'images/logo/threads_wordmark.png';

type Props = {};

function LoginPage(props: Props): JSX.Element {
  return (
    <div className={styles.login_container}>
      <div className={styles.logo}>
        <img src={logo} alt="Threads" />
      </div>
      <LoginForm />
    </div>
  );
}

export default LoginPage;
