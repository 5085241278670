import * as React from 'react';
import { Link } from 'react-router-dom';
import Button, { ButtonColour } from './Button';

type Props = {
  href: string;
  isInternalLink: boolean;
  label: React.ReactNode;
  subtle: boolean;
  target?: string;
  colour: ButtonColour;
  tooltip?: string;
  tooltipDirection?:
    | 'up'
    | 'down'
    | 'left'
    | 'right'
    | 'up-left'
    | 'up-right'
    | 'down-left'
    | 'down-right';
};

function LinkButton(props: Props): JSX.Element {
  const content = (
    <Button
      label={props.label}
      onClick={() => {}}
      subtle={props.subtle}
      colour={props.colour}
      tooltip={props.tooltip}
      tooltipDirection={props.tooltipDirection}
    />
  );
  return props.isInternalLink ? (
    <Link to={props.href} style={{ textDecoration: 'none' }}>
      {content}
    </Link>
  ) : (
    <a
      href={props.href}
      target={props.target}
      rel="noreferrer"
      style={{ textDecoration: 'none' }}
    >
      {content}
    </a>
  );
}

LinkButton.defaultProps = {
  isInternalLink: false,
  subtle: false,
  colour: 'normal',
};

export default LinkButton;
