import * as React from 'react';
import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { TextInput, ButtonContainer, Button } from '../../ui/forms';
import NoticeCard from '../../ui/containers/NoticeCard';
import firebase from 'firebase/app';

type Props = {
  postLoginRedirectHref: string;
};

function LoginForm({ postLoginRedirectHref }: Props): JSX.Element {
  const history = useHistory();
  const [emailAddress, setEmailAddress] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  const onSubmit = useCallback(() => {
    setError(null);
    setIsLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(emailAddress, password)
      .then((result) => {
        setIsLoading(false);
        if (!!result.user) {
          history.push(postLoginRedirectHref);
        }
      })
      .catch((error) => {
        setIsLoading(false);
        setError(error.message);
      });
  }, [
    emailAddress,
    password,
    setError,
    setIsLoading,
    postLoginRedirectHref,
    history,
  ]);

  return (
    <>
      <TextInput
        label="Email address"
        value={emailAddress}
        onChange={setEmailAddress}
        focusOnLoad={true}
      />
      <TextInput
        type="password"
        label="Password"
        value={password}
        onChange={setPassword}
        onHitEnter={onSubmit}
      />
      <ButtonContainer>
        <Button label="Log in" onClick={onSubmit} showSpinner={isLoading} />
      </ButtonContainer>
      {error && (
        <NoticeCard type="error" title="Could not log you in">
          {error}
        </NoticeCard>
      )}
    </>
  );
}

LoginForm.defaultProps = {
  postLoginRedirectHref: '/',
};

export default LoginForm;
