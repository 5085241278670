import firebase from 'firebase/app';
import { useCallback, useState } from 'react';
import OperationState from '../helpers/OperationState';

function useFirestoreDeletion({
  collection,
  documentID,
}: {
  collection: string;
  documentID: string;
}): {
  deleteObject: () => void;
  state: OperationState;
} {
  const [state, setState] = useState<OperationState>(OperationState.PENDING);
  const deleteObject = useCallback(() => {
    setState(OperationState.IN_PROGRESS);
    firebase
      .firestore()
      .collection(collection)
      .doc(documentID)
      .delete()
      .then(() => {
        setState(OperationState.SUCCESS);
      })
      .catch(() => {
        console.warn(`Failed to delete document ${documentID}.`);
        setState(OperationState.FAILED);
      });
  }, [setState, collection, documentID]);

  return {
    deleteObject,
    state,
  };
}

export default useFirestoreDeletion;
