import * as React from 'react';
import classnames from 'classnames';
import firebase from 'firebase/app';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './CurrentUserProfilePhoto.module.css';

type Props = {
  size: 'small' | 'medium' | 'large';
};

function CurrentUserProfilePhoto({ size }: Props): JSX.Element {
  const user = firebase.auth().currentUser;
  const photoURL = user?.photoURL;

  return photoURL ? (
    <div
      className={classnames({
        [styles.photo]: true,
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
      })}
      style={{
        backgroundImage: `url(${photoURL})`,
      }}
    />
  ) : (
    <div
      className={classnames({
        [styles.photo]: true,
        [styles.small]: size === 'small',
        [styles.medium]: size === 'medium',
        [styles.large]: size === 'large',
      })}
    >
      {user?.displayName ? (
        <>{user.displayName[0].toUpperCase()}</>
      ) : (
        <FontAwesomeIcon icon={faUserAlt} />
      )}
    </div>
  );
}

CurrentUserProfilePhoto.defaultProps = {
  size: 'medium',
};

export default CurrentUserProfilePhoto;
