import { useEffect, useState } from 'react';
import firebase from 'firebase/app';

function useFirebaseUser() {
  const [user, setUser] = useState<firebase.User | null>(null);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((user) => {
      setUser(user);
    });
  }, []);

  return user;
}

export default useFirebaseUser;
