import * as React from 'react';
import classnames from 'classnames';

import styles from './Tag.module.css';

type Props = {
  children: React.ReactNode;
  colour: 'normal' | 'highlight' | 'positive' | 'negative' | 'accent';
};

function Tag({ children, colour }: Props): JSX.Element {
  return (
    <div
      className={classnames({
        [styles.tag]: true,
        [styles.colour_normal]: colour === 'normal',
        [styles.colour_highlight]: colour === 'highlight',
        [styles.colour_positive]: colour === 'positive',
        [styles.colour_negative]: colour === 'negative',
        [styles.colour_accent]: colour === 'accent',
      })}
    >
      {children}
    </div>
  );
}

Tag.defaultProps = {
  colour: 'normal',
};

export default Tag;
