import * as React from 'react';
import classnames from 'classnames';

import FormField from './FormField';

import styles from './ButtonGroup.module.css';

type Props = {
  items: Array<{
    label: string;
    value: string;
  }>;
  helpText?: string;
  label?: string;
  onChange: (selectedValue: string) => void;
  value: string;
};

function ButtonGroup(props: Props): JSX.Element {
  return (
    <FormField label={props.label} helpText={props.helpText}>
      <div className={styles.button_group}>
        {props.items.map((item) => (
          <button
            className={classnames({
              [styles.selected]: item.value === props.value,
            })}
            key={item.value}
            onClick={() => {
              props.onChange(item.value);
            }}
          >
            {item.label}
          </button>
        ))}
      </div>
    </FormField>
  );
}

export default ButtonGroup;
