import * as React from 'react';

import styles from './Grid.module.css';

type Props = {
  children: React.ReactNode;
  columns: number;
};

function Grid({ children, columns }: Props): JSX.Element {
  return (
    <div
      className={styles.grid}
      style={{
        gridTemplateColumns: `repeat(${columns}, 1fr)`,
      }}
    >
      {children}
    </div>
  );
}

export default Grid;
