import * as React from 'react';
import { useCallback, useState } from 'react';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import CollapsibleContainer from './CollapsibleContainer';

import styles from './Card.module.css';

type Props = {
  children: React.ReactNode;
  title: string;
};

function CollapsibleCard({ title, children }: Props): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);

  const onClickTitle = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [setIsExpanded, isExpanded]);

  return (
    <div
      className={classnames({
        [styles.card]: true,
        [styles.collapsible_card]: true,
        [styles.expanded]: isExpanded,
      })}
      style={{}}
    >
      <h2 className={styles.title} onClick={onClickTitle}>
        <div>{title}</div>
        <div className={styles.icon_container}>
          <FontAwesomeIcon className={styles.icon} icon={faCaretRight} />
        </div>
      </h2>
      <CollapsibleContainer expanded={isExpanded}>
        {children}
      </CollapsibleContainer>
    </div>
  );
}

export default CollapsibleCard;
