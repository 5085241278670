import * as React from 'react';
import firebase from 'firebase/app';
import { Text } from '../../ui/text';
import { LinkButton } from '../../ui/forms';
import CurrentUserProfilePhoto from './CurrentUserProfilePhoto';
import LogOutButton from './LogOutButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

import styles from './CurrentUserProfileWidget.module.css';

type Props = {
  settingsHref?: string;
};

function CurrentUserProfileWidget({ settingsHref }: Props): JSX.Element | null {
  const user = firebase.auth().currentUser;
  const isMobile = window.matchMedia('(max-width: 1000px)').matches;

  if (!user) {
    return null;
  }

  return (
    <div className={styles.container}>
      <div className={styles.profile_photo_container}>
        <CurrentUserProfilePhoto />
      </div>
      <div className={styles.user_details_container}>
        <div>
          <Text weight="bold" color="heading">
            {user.displayName ?? user.email}
          </Text>
        </div>
        <div>
          {settingsHref && (
            <LinkButton
              label={<FontAwesomeIcon icon={faCog} />}
              href={settingsHref}
              isInternalLink={true}
              tooltip="Settings"
              subtle={!isMobile}
            />
          )}{' '}
          <LogOutButton
            label={<FontAwesomeIcon icon={faSignOutAlt} />}
            tooltip="Log out"
            subtle={!isMobile}
          />
        </div>
      </div>
    </div>
  );
}

export default CurrentUserProfileWidget;
