import * as React from 'react';
import { useCallback, useState } from 'react';
import CurrentUserProfilePhoto from './CurrentUserProfilePhoto';
import {
  TextInput,
  FormField,
  FileInput,
  ButtonContainer,
  Button,
} from '../../ui/forms';
import { NoticeCard } from '../../ui/containers';
import firebase from 'firebase/app';
import UserProfile, {
  useUserProfileCollectionOnce,
  useUserProfileMutation,
} from 'data/UserProfile';

function ProfileDetailsUpdateForm({
  onFinishedSaving,
}: {
  onFinishedSaving?: () => void;
}): JSX.Element {
  const user = firebase.auth().currentUser;
  const storage = firebase.storage();

  const [existingProfiles] = useUserProfileCollectionOnce([
    { field: 'user_id', op: '==', value: user?.uid },
  ]);
  const { save: savePublicProfile } = useUserProfileMutation(
    existingProfiles.length > 0 ? existingProfiles[0].getID() : undefined
  );

  const [displayName, setDisplayName] = useState(user?.displayName ?? '');
  const [newProfilePhotoFile, setNewProfilePhotoFile] = useState<File | null>(
    null
  );
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState<null | boolean>(null);

  const onSave = useCallback(async () => {
    if (!user) {
      return;
    }

    setIsSaving(true);
    const payload = {
      displayName,
      photoURL: undefined,
    };

    let publicProfile =
      existingProfiles.length > 0
        ? existingProfiles[0]
        : new UserProfile().set('userID', user.uid);
    publicProfile.set('displayName', displayName);

    try {
      if (!!newProfilePhotoFile) {
        const ref = storage
          .ref()
          .child(
            `profile_photos/${user.uid}${newProfilePhotoFile.name.slice(
              newProfilePhotoFile.name.lastIndexOf('.')
            )}`
          );
        const uploadedFile = await ref.put(newProfilePhotoFile);
        const url = await uploadedFile.ref.getDownloadURL();
        payload.photoURL = url;
        publicProfile.set('profilePhotoURL', url);
      }

      await user.updateProfile(payload);

      savePublicProfile(publicProfile);

      setIsSaving(false);
      setSaveSuccess(true);
      onFinishedSaving && onFinishedSaving();
    } catch (e) {
      console.warn(e);
      setIsSaving(false);
      setSaveSuccess(false);
    }
  }, [
    displayName,
    user,
    setIsSaving,
    setSaveSuccess,
    newProfilePhotoFile,
    storage,
    savePublicProfile,
    existingProfiles,
    onFinishedSaving,
  ]);

  return (
    <>
      <TextInput
        label="Display name"
        value={displayName}
        onChange={setDisplayName}
        disabled={isSaving}
      />
      <FormField label="Profile photo">
        <>
          <CurrentUserProfilePhoto />
          <FileInput accept="image/*" onFileSelected={setNewProfilePhotoFile} />
        </>
      </FormField>

      <ButtonContainer>
        <Button
          label="Update profile"
          onClick={onSave}
          showSpinner={isSaving}
          disabled={!displayName.trim()}
        />
      </ButtonContainer>
      {saveSuccess != null && (
        <NoticeCard
          title={saveSuccess ? 'Profile saved' : 'Could not save profile'}
          type={saveSuccess ? 'success' : 'error'}
        >
          {saveSuccess
            ? 'Your profile details have been updated!'
            : "Your profile details couldn't be saved at the moment. Try again later."}
        </NoticeCard>
      )}
    </>
  );
}

export default ProfileDetailsUpdateForm;
