import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/remote-config';
import 'firebase/storage';

const config = {
  appId: '1:1056206091507:web:d7561e6f21aa19328eb386',
  apiKey: 'AIzaSyATGzZadEF4z2XVMeoJzb9ks6VsVIfxib8',
  authDomain: 'threads-a9722.firebaseapp.com',
  databaseURL: 'https://threads-a9722.firebaseio.com',
  projectId: 'threads-a9722',
  storageBucket: 'threads-a9722.appspot.com',
};

if (!firebase.apps.length) {
  console.log('Starting...');
  firebase.initializeApp(config);
}

export const auth = firebase.auth;
export const db = firebase.firestore();
export const remoteConfig = firebase.remoteConfig();
export const storage = firebase.storage();
