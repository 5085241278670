import * as React from 'react';
import { useCallback, useState, useMemo, useEffect } from 'react';
import classnames from 'classnames';
import FormField from './FormField';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import styles from './Select.module.css';

type Props = {
  label?: string;
  helpText?: string;
  options: Array<{
    label: React.ReactNode;
    value: string;
  }>;
  value: string;
  onChange: (value: string) => void;
};

function Select({
  label,
  helpText,
  options,
  value,
  onChange,
}: Props): JSX.Element {
  const [shouldShowOptions, setShouldShowOptions] = useState(false);
  const onOptionSelect = useCallback(
    (selectedValue: string) => {
      onChange(selectedValue);
    },
    [onChange]
  );
  const selectedOption = useMemo(
    () => options.find((option) => option.value === value),
    [options, value]
  );

  useEffect(() => {
    const onDocumentClick = () => {
      setShouldShowOptions(false);
      document.removeEventListener('click', onDocumentClick);
    };

    if (shouldShowOptions) {
      document.addEventListener('click', onDocumentClick);
    }
  }, [shouldShowOptions, setShouldShowOptions]);

  return (
    <FormField label={label} helpText={helpText}>
      <div className={styles.container}>
        <div
          className={classnames({
            [styles.selector]: true,
            [styles.is_expanded]: shouldShowOptions,
          })}
          onClick={() => {
            setShouldShowOptions(!shouldShowOptions);
          }}
        >
          <div className={styles.selector_content}>
            <div>{selectedOption && <>{selectedOption.label}</>}</div>
            <FontAwesomeIcon
              icon={faCaretDown}
              className={classnames({
                [styles.arrow]: true,
                [styles.is_expanded]: shouldShowOptions,
              })}
            />
          </div>
        </div>

        {shouldShowOptions && (
          <ul className={styles.options}>
            {options.map((option) => (
              <li
                key={option.value}
                onClick={() => {
                  setShouldShowOptions(false);
                  onOptionSelect(option.value);
                }}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </FormField>
  );
}

export default Select;
