import * as React from 'react';
import classnames from 'classnames';

import './Spinner.css';

type Props = {
  display: 'block' | 'inline';
  size: 'small' | 'large';
};

/**
 * @deprecated Use Loading where possible.
 */
function Spinner(props: Props): JSX.Element {
  return (
    <div
      className={classnames({
        'sk-fading-circle': true,
        'sp-large': props.size === 'large',
        'sp-small': props.size === 'small',
        'sp-inline': props.display === 'inline',
      })}
    >
      <div className="sk-circle1 sk-circle"></div>
      <div className="sk-circle2 sk-circle"></div>
      <div className="sk-circle3 sk-circle"></div>
      <div className="sk-circle4 sk-circle"></div>
      <div className="sk-circle5 sk-circle"></div>
      <div className="sk-circle6 sk-circle"></div>
      <div className="sk-circle7 sk-circle"></div>
      <div className="sk-circle8 sk-circle"></div>
      <div className="sk-circle9 sk-circle"></div>
      <div className="sk-circle10 sk-circle"></div>
      <div className="sk-circle11 sk-circle"></div>
      <div className="sk-circle12 sk-circle"></div>
    </div>
  );
}

Spinner.defaultProps = {
  display: 'block',
  size: 'large',
};

export default Spinner;
