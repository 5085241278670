import * as React from 'react';
import { useEffect, useMemo } from 'react';
import classnames from 'classnames';
import FormField from './FormField';

import styles from './TypeaheadTextInput.module.css';

type Props = {
  disabled: boolean;
  focusOnLoad?: boolean;
  helpText?: string;
  label?: string;
  onChange: (value: string) => void;
  onSelectItem?: (value: string) => void;
  onFocus?: () => void;
  options: Array<string>;
  maxTypeaheadOptions: number;
  placeholder: string;
  prefix?: React.ReactNode;
  typeaheadTriggerChars: number;
  value: string;
};

function TypeaheadTextInput(props: Props): JSX.Element {
  let textInput: HTMLInputElement | null = null;
  const { value, options, maxTypeaheadOptions } = props;

  useEffect(() => {
    if (!!props.focusOnLoad && !!textInput && !props.value) {
      textInput.focus();
    }
  }, [textInput, props.value, props.focusOnLoad]);

  const filteredOptions = useMemo(
    () =>
      options
        .filter((opt) => opt.toLowerCase().includes(value.toLowerCase()))
        .sort(),
    [value, options]
  );
  const shouldShowOptions =
    value.length >= props.typeaheadTriggerChars &&
    !options.includes(value) &&
    filteredOptions.length > 0;

  return (
    <FormField label={props.label} helpText={props.helpText}>
      <div className={styles.container}>
        <div
          className={classnames({
            [styles.input_container]: true,
            [styles.options_visible]: shouldShowOptions,
          })}
        >
          {props.prefix && <div className={styles.prefix}>{props.prefix}</div>}
          <input
            className={classnames({
              [styles.text_input]: true,
              [styles.disabled]: props.disabled,
            })}
            disabled={props.disabled}
            type="text"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              props.onChange(e.currentTarget.value);
            }}
            onFocus={props.onFocus}
            placeholder={props.placeholder}
            ref={(input) => {
              textInput = input;
            }}
            value={props.value}
          />
        </div>
        {shouldShowOptions && (
          <ul className={styles.options}>
            {filteredOptions.slice(0, maxTypeaheadOptions).map((option) => (
              <li
                key={option}
                onClick={() => {
                  props.onChange(option);
                  props.onSelectItem && props.onSelectItem(option);
                }}
              >
                {option}
              </li>
            ))}
            {filteredOptions.length > maxTypeaheadOptions && (
              <li className={styles.more_options}>
                ... and {filteredOptions.length - maxTypeaheadOptions} more
              </li>
            )}
          </ul>
        )}
      </div>
    </FormField>
  );
}

TypeaheadTextInput.defaultProps = {
  disabled: false,
  maxTypeaheadOptions: 10,
  placeholder: '',
  typeaheadTriggerChars: 3,
};

export default TypeaheadTextInput;
