import * as React from 'react';
import classnames from 'classnames';
import { useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

import styles from './Checkbox.module.css';

type Props = {
  onChange: (checked: boolean) => void;
  checked: boolean;
  label?: React.ReactNode;
};

function Checkbox(props: Props): JSX.Element {
  const { onChange, checked } = props;
  const onClick = useCallback(() => {
    onChange(!checked);
  }, [onChange, checked]);

  return (
    <div className={styles.container}>
      <button className={styles.checkbox} onClick={onClick}>
        <FontAwesomeIcon
          className={classnames({
            [styles.checkmark]: true,
            [styles.checked]: checked,
          })}
          icon={faCheck}
        />
      </button>
      {props.label}
    </div>
  );
}

export default Checkbox;
