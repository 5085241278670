import * as React from 'react';
import { useCallback, useState } from 'react';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import Heading from '../text/Heading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';

import styles from './Sidebar.module.css';

export type Props = {
  appName: string;
  icon?: React.ReactNode;
  showAppName: boolean;
  homeHref: string;
  children: React.ReactNode;
  version?: string;
  footerContent?: React.ReactNode;
};

function Sidebar({
  appName,
  icon,
  showAppName,
  homeHref,
  children,
  version,
  footerContent,
}: Props): JSX.Element {
  const [isNavVisible, setIsNavVisible] = useState(false);
  const onNavToggle = useCallback(() => {
    setIsNavVisible(!isNavVisible);
  }, [isNavVisible, setIsNavVisible]);

  return (
    <div className={styles.container}>
      <div className={styles.nav_toggle} onClick={onNavToggle}>
        <FontAwesomeIcon icon={faBars} />
      </div>

      <div
        className={classnames({
          [styles.content]: true,
          [styles.visible]: isNavVisible,
        })}
      >
        <Link to={homeHref} className={styles.home_link}>
          <div className={styles.app_header}>
            {icon && <div className={styles.icon}>{icon}</div>}
            {showAppName && (
              <Heading type="section-title" subtitle={version}>
                {appName}
              </Heading>
            )}
          </div>
        </Link>
        {children}
        {footerContent && <div className={styles.footer}>{footerContent}</div>}
      </div>
    </div>
  );
}

Sidebar.defaultProps = {
  showAppName: true,
};

export default Sidebar;
