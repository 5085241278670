import getFirestoreDocumentQuery from '../helpers/getFirestoreDocumentQuery';
import { useEffect, useState } from 'react';
import useFirebaseUser from '../../auth/hooks/useFirebaseUser';
import { IFirestoreTransformable } from '../types/FirestoreObject';

function useFirestoreDocumentOnce<TObjectType extends IFirestoreTransformable>(
  {
    collection,
    documentID,
    create,
  }: {
    collection: string;
    documentID: string | null;
    create: () => TObjectType;
  },
  waitForAuth: boolean = true
): [result: TObjectType | null, isLoading: boolean] {
  const user = useFirebaseUser();
  const [result, setResult] = useState<TObjectType | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const createFunc = create.toString();

  useEffect(
    () => {
      if (waitForAuth && user === null) {
        setResult(null);
        return;
      }

      if (documentID === null) {
        setResult(null);
        return;
      }

      getFirestoreDocumentQuery({
        collection,
        documentID,
      })
        .get()
        .then((doc) => {
          try {
            const data = doc.data();
            if (!data) {
              throw new Error(`Document ${documentID} could not be loaded.`);
            }
            const item = create().loadFromFirestoreData(doc.id, data);
            setResult(item);
          } catch (e: any) {
            console.warn(
              `Error when parsing document ${documentID}: ${e.message}`
            );
          }
          setIsLoading(false);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      waitForAuth,
      setResult,
      setIsLoading,
      collection,
      documentID,
      user,
      createFunc,
    ]
  );

  return [result, isLoading];
}

export default useFirestoreDocumentOnce;
