import * as React from 'react';
import Text, { Props } from './Text';

import styles from './Paragraph.module.css';

function Paragraph(props: Props): JSX.Element {
  return (
    <div className={styles.paragraph}>
      <Text {...props}>{props.children}</Text>
    </div>
  );
}

Paragraph.defaultProps = Text.defaultProps;

export default Paragraph;
