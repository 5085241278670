import * as React from 'react';
import { faDotCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import Heading from '../text/Heading';

import styles from './Timeline.module.css';

type TimelineEntryProps = {
  icon: IconProp;
  children: React.ReactNode;
  markerColour?: string;
};

function TimelineEntry({
  children,
  icon,
  markerColour,
}: TimelineEntryProps): JSX.Element {
  return (
    <div className={styles.entry}>
      <div className={styles.marker} style={{ color: markerColour }}>
        <FontAwesomeIcon icon={icon} />
      </div>
      <div className={styles.content}>{children}</div>
    </div>
  );
}

function Timeline({
  title,
  children,
}: {
  title: string;
  children:
    | React.ReactElement<TimelineEntryProps>
    | Array<React.ReactElement<TimelineEntryProps>>;
}): JSX.Element {
  return (
    <div className={styles.container}>
      <Heading type="section-title">{title}</Heading>
      <div className={styles.timeline}>{children}</div>
    </div>
  );
}

TimelineEntry.defaultProps = {
  icon: faDotCircle,
};

export { Timeline, TimelineEntry };
