import * as React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';

import styles from './Flyout.module.css';

type Props = {
  children: React.ReactNode;
  onClose: () => void;
  header?: React.ReactNode;
};

function Flyout({ children, onClose, header }: Props): JSX.Element {
  return (
    <div className={styles.backdrop}>
      <div className={styles.container}>
        <div className={styles.header}>
          <div className={styles.back_button} onClick={onClose}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </div>
          {header}
        </div>
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
}

export default Flyout;
