import * as React from 'react';
import { useCallback, useRef, useState } from 'react';
import Button from './Button';

import styles from './DelayedButton.module.css';

type Props = {
  disabled?: boolean;
  label: React.ReactNode;
  onClick: () => void;
  primary?: boolean;
  showSpinner?: boolean;
  subtle: boolean;
  stopEventPropagation: boolean;
  tooltip?: string;
  tooltipDirection?:
    | 'up'
    | 'down'
    | 'left'
    | 'right'
    | 'up-left'
    | 'up-right'
    | 'down-left'
    | 'down-right';

  delaySeconds: number;
  cancelLabel: React.ReactNode;
};

function DelayedButton(props: Props): JSX.Element {
  const { onClick, delaySeconds } = props;
  const tick = useRef<any>(null);
  const [timeLeft, setTimeLeft] = useState(0);
  const [isTicking, setIsTicking] = useState(false);
  const onButtonClick = useCallback(() => {
    if (isTicking) {
      tick.current && clearInterval(tick.current);
      setTimeLeft(0);
      setIsTicking(false);
      return;
    }

    const targetTime = new Date().getTime() + 1000 * delaySeconds;
    setTimeLeft(1000 * delaySeconds);
    setIsTicking(true);

    tick.current = setInterval(() => {
      const now = new Date().getTime();
      if (now >= targetTime) {
        tick.current && clearInterval(tick.current);
        setTimeLeft(0);
        setIsTicking(false);
        onClick();
        return;
      }

      setTimeLeft(targetTime - now);
    }, 50);
  }, [onClick, delaySeconds, tick, setTimeLeft, setIsTicking, isTicking]);
  return (
    <Button
      {...props}
      onClick={onButtonClick}
      label={
        <div className={styles.container}>
          {isTicking ? props.cancelLabel : props.label}
          <div className={styles.progress}>
            {timeLeft > 0 && (
              <progress max={1000 * delaySeconds} value={timeLeft} />
            )}
          </div>
        </div>
      }
    />
  );
}

DelayedButton.defaultProps = {
  disabled: false,
  primary: false,
  showSpinner: false,
  stopEventPropagation: false,
  subtle: false,
  tooltipDirection: 'up',
  cancelLabel: 'Cancel',
};

export default DelayedButton;
