import firebase from 'firebase/app';

export type QueryWhereClause = {
  field: string | firebase.firestore.FieldPath;
  op: firebase.firestore.WhereFilterOp;
  value: any;
};

function getFirestoreCollectionQuery({
  collection,
  filters = [],
}: {
  collection: string;
  filters?: Array<QueryWhereClause>;
}): firebase.firestore.Query<firebase.firestore.DocumentData> {
  const db = firebase.firestore();
  let fsQuery: firebase.firestore.Query;
  if (filters.length === 0) {
    fsQuery = db.collection(collection);
  } else {
    const firstClause = filters[0];
    fsQuery = db
      .collection(collection)
      .where(firstClause.field, firstClause.op, firstClause.value);
    filters.slice(1).forEach((clause) => {
      fsQuery = fsQuery.where(clause.field, clause.op, clause.value);
    });
  }

  return fsQuery;
}

export default getFirestoreCollectionQuery;
