/* eslint-disable react-hooks/exhaustive-deps */
import getFirestoreCollectionQuery, {
  QueryWhereClause,
} from '../helpers/getFirestoreCollectionQuery';
import { useEffect, useState } from 'react';
import useFirebaseUser from '../../auth/hooks/useFirebaseUser';
import { IFirestoreTransformable } from '../types/FirestoreObject';

function useFirestoreCollection<TObjectType extends IFirestoreTransformable>(
  {
    collection,
    filters = [],
    create,
  }: {
    collection: string;
    filters?: Array<QueryWhereClause>;
    create: () => TObjectType;
  },
  waitForAuth: boolean = true,
  deps: Array<any> = []
): [results: Array<TObjectType>, isLoading: boolean] {
  const user = useFirebaseUser();
  const [results, setResults] = useState<Array<TObjectType>>([]);
  const [isLoading, setIsLoading] = useState(true);
  const createFunc = create.toString();

  useEffect(() => {
    if (waitForAuth && user === null) {
      return;
    }

    const unsubscribe = getFirestoreCollectionQuery({
      collection,
      filters,
    }).onSnapshot((snapshot) => {
      const snapshotResults: Array<TObjectType> = [];
      snapshot.forEach((result) => {
        try {
          snapshotResults.push(
            create().loadFromFirestoreData(result.id, result.data())
          );
        } catch (e: any) {
          console.warn(`Error when parsing document: ${e.message}`);
        }
      });
      setResults(snapshotResults);
      setIsLoading(false);
    });

    return () => {
      unsubscribe();
    };
  }, [
    waitForAuth,
    setResults,
    setIsLoading,
    collection,
    user,
    createFunc,
    ...deps,
  ]);

  return [results, isLoading];
}

export default useFirestoreCollection;
