import * as React from 'react';
import classnames from 'classnames';
import { NavLink as RNavLink } from 'react-router-dom';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import CollapsibleContainer from '../containers/CollapsibleContainer';

import styles from './NavLink.module.css';
import { useCallback, useState } from 'react';

type NavLinkProps = {
  icon?: IconProp;
  label: string;
  href: string;
  highlightDirection: 'right' | 'bottom';
};

function NavLink({
  icon,
  label,
  href,
  highlightDirection,
}: NavLinkProps): JSX.Element {
  return (
    <RNavLink
      to={href}
      activeClassName={styles.active}
      className={classnames({
        [styles.link]: true,
        [styles.highlight_right]: highlightDirection === 'right',
        [styles.highlight_bottom]: highlightDirection === 'bottom',
      })}
      exact={true}
    >
      <div className={styles.icon}>
        {icon && <FontAwesomeIcon icon={icon} />}
      </div>
      <div>{label}</div>
    </RNavLink>
  );
}

function NavLinkSection({
  title,
  children,
}: {
  title: string;
  children:
    | React.ReactElement<NavLinkProps>
    | Array<React.ReactElement<NavLinkProps>>;
}): JSX.Element {
  const [isExpanded, setIsExpanded] = useState(false);
  const onToggle = useCallback(() => {
    setIsExpanded(!isExpanded);
  }, [isExpanded, setIsExpanded]);

  return (
    <div
      className={classnames({
        [styles.link_section]: true,
        [styles.expanded]: isExpanded,
      })}
    >
      <div className={styles.title_container} onClick={onToggle}>
        <div className={styles.title_content}>{title}</div>
        <div className={styles.chevron}>
          <FontAwesomeIcon icon={faCaretRight} />
        </div>
      </div>
      <CollapsibleContainer expanded={isExpanded} className={styles.content}>
        {children}
      </CollapsibleContainer>
    </div>
  );
}

function NavHeader({ title }: { title: string }): JSX.Element {
  return (
    <div className={classnames([styles.title_content, styles.nav_header])}>
      {title}
    </div>
  );
}

NavLink.defaultProps = {
  highlightDirection: 'right',
};

export { NavLink, NavLinkSection, NavHeader };
